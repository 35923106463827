/* Provide sufficient contrast against white background */
body{
  /* background-color:transparent !important; */
  width: 100%;
  height: auto;
  background: #000000 url("./assets/images/background.jpg") center top no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.jumbotron{
  margin-top: 10px;
  margin-bottom: 50px;
}

.top-nav{
  background: #010101;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  color: #ffffff;
  position: relative;
}

.top-nav-text{
   float: right !important;
   padding: 10px;;
}

.top-nav-social-ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.top-nav-social-ul li{
  font-size:30px;
  margin:1px;
}

.top-nav-social-ul a{
  text-decoration:none;
  color:white;
}

.top-nav-social-ul a:hover{
  text-decoration:none;
  color: rgb(180, 66, 66) !important;
}

.btn-primary {
  color: #fff;
  background-color: #534949 !important;
  border-color: #524545 !important  ;
} 

.main-container{
  /* background-color: #303030; */
  /* background-color: #212529; */
  background: transparent !important;
}

h1, h2, h3, h4, h5, h6{
  color: white !important;
}

span {
  color: white;
}

.form-label{
  color:white;
}

.footer {
  background-color: #212529;
  padding: 15px;
  color: white;
  text-align: center;
  min-height: 50px;
}

.ran-card {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid  #212529 !important; 
  border-top-left-radius: .30rem !important;
  border-top-right-radius: .30rem !important;
  border-bottom-left-radius: .30rem !important;
  border-bottom-right-radius: .30rem !important;

  border-top-color:#212529;
  border-right-color:#212529;
  border-bottom-color: #212529;
  border-left-color:#911515;
}

.ran-card-header{
  color: white;
  background-color: #383b3d !important;
  font-weight: bold;
  border-left: 5px solid #911515;
  text-transform: uppercase;
}

.ran-card .card-title{
  color: white;
}
.ran-card .card-body {
  background-color: #212529;
}

.btn-register{
  float: right;
  color:white !important;
}

.individual-rankings-item {
  display: flex !important;
  align-items: flex-start !important;
  background-color: #212529 !important;
}

.individual-rankings-item .name {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2px;
}

.individual-rankings-item div span{
  color:white;
}

.individual-rankings-item .d-flex {
  margin-right: 10px;
}

.clubwars-item {
  display: flex !important;
  align-items: flex-start !important;
  background-color: #212529 !important;
}

.clubwars-item .name {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2px;
}

.clubwars-item div span{
  color:white;
}

.clubwars-item .d-flex {
  margin-right: 10px;
}

.news-content-body {
  padding: 20px !important;
}

.news-data-item {
  margin-bottom: 10px;;
}

.news-data-item a {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.news-data-item a:hover {
  color: rgb(180, 66, 66) !important;
}

.news-data-item .badge {
  float: right !important;
}

.downloads-item {
  display: flex !important;
  align-items: flex-start !important;
  background-color: #212529 !important;
}

.downloads-item a {
  text-decoration: none;
  color: #fff;
}

.downloads-item a:visited a:active {
  text-decoration: none;
  color: #fff;
}

.downloads-item a:hover {
  color: rgb(180, 66, 66) !important;
}

.midman-card .card {
  background-color:transparent !important;
}

.midman-card-body  {
  background-color:transparent !important;
}

.midman-card-body a {
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

.midman-card-body a:visited a:active {
  text-decoration: none;
  color: #fff;
}

.midman-card-body a:hover {
  color: rgb(180, 66, 66) !important;
}

.rankings-btn-group button {
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}

.rankings-list li {
  color: #fff;
  background-color: #303030 !important;
}

.rankings-item {
  display: flex !important;
  align-items: flex-start !important;
  background-color: #303030 !important;
  padding: 10px !important;
}

.rankings-item:hover {
  color: #fff;
  background-color: #212529 !important;
}

.rankings-item .name {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2px;
}

.rankings-item .info {
  color: white;
  font-size: 18px;
}

.rankings-item .d-flex {
  margin-right: 10px;
}

.rankings-item-body{
  width: 100%;
  color :#fff;
}

.rankings-item-body .badge {
  float: right !important;
}

.top-up-card {
  background-color: transparent !important;
  width: 50% !important;
  border: none !important;
}

.user-panel-item {
  color: #b9b6b6 !important;
  background-color: #303030 !important;
}

.user-panel-item-menu-active {
  color: #fff !important;
}

.user-panel-item a {
  color: #b9b6b6;
  text-decoration: none;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}

.user-panel-item a:hover {
  color:white !important;
}

.forgot-password a {
  color: #fff !important;
  text-decoration: none;
  margin-left: 10px;
  font-size: 14px;
}

.forgot-password a:hover {
  color: rgb(180, 66, 66) !important;
}

.ran-footer a {
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

.ran-footer a:visited a:active {
  text-decoration: none;
  color: #fff;
}

.ran-footer a:hover {
  color: rgb(180, 66, 66) !important;
}

.items .shop-item{
  background: #222325;
  padding: 5px;
  border: 1px solid #363636;
}

.row .items{
  margin-bottom: 10px;
}

.items .item-data{
  position: relative;
}

.items .shop-item .item-data .title {
  font-size: 13px;
  color: #fff;
  font-weight: bold;
}

.items .shop-item .item-data .price {
  font-size: 15px;
  display: inline-block;
  margin-left: 5px;
}

.items .shop-item .item-data .action {
  position: absolute;
  right: 0px;
  bottom: -39px;
  margin-top: 5px;
}

.items .shop-item img {
  float: left;
  width: 110px;
  height: 110px;
  border-radius: 5px;
  margin-right: 8px;
}

.react-confirm-alert-overlay{
  background-color: transparent !important;
}

.react-confirm-alert-body{
  padding: 15px I !important;
}

.react-confirm-alert-body h1{
  color: black !important;
}